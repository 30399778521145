import React, { useState } from "react";

const SnackbarContext = React.createContext({
  msg: "",
  status: "neutral",
  isDisplayed: false,
  displayMsg: (msg, status) => {},
  onClose: () => {},
});

let timer;

export const SnackBarContextProvider = (props) => {
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("neutral");
  const [isDisplayed, setIsDisplayed] = useState(false);

  const displayHandler = (msg, status) => {
    setMsg(msg);
    setStatus(status);
    setIsDisplayed(true);
    timer = setTimeout(() => {
      closeHandler();
    }, 10000); // close snackbar after 10 seconds
  };
  const closeHandler = () => {
    clearTimeout(timer);
    setIsDisplayed(false);
  };

  return (
    <SnackbarContext.Provider value={{msg, status, isDisplayed, displayMsg: displayHandler, onClose: closeHandler}}>
      {props.children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;