import React from "react";
import {Table, TableHeading, TableCell, Skeleton} from "@nike/eds";
import {RequestSearchResultItem} from "./RequestSearchResultItem";

function RequestSearchResults(props) {

    const headers = [
        {Header: "Event timestamp"},
        {Header: "Inventory Location"},
        {Header: "Source ID"},
        {Header: "Inventory type"},
        {Header: "Business Reference"},
        {Header: "Error description"},
        {Header: "Details"}
    ];

    return (
         <div className="eds-grid eds-grid--m-cols-6 eds-elevation--4">
            <Table className="eds-grid--m-col-6 eds-grid--item-align-start">
                <thead>
                <tr>
                    {headers.map(column => (<TableHeading key={column.Header}>{column.Header}</TableHeading>))}
                </tr>
                </thead>
                <tbody>
                { props.loading &&
                    <>
                        <tr>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        </tr>
                    </>
                }
                {!props.loading && props.searchResults.map(searchResult => (
                    <RequestSearchResultItem key={searchResult.traceId} searchResult={searchResult}/>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

export {RequestSearchResults};
