import React from "react";
import { TableCell } from "@nike/eds";
import { IconButton } from "@nike/eds";
import { Link } from "react-router-dom";
import { mapInventoryLocation } from "../shared/InventoryLocationMapper";
import { formatDate } from "../shared/DateFormatter";

function RequestSearchResultItem({searchResult}) {

    const cellStyle = {
        color : searchResult.errorCode ? 'red' : 'black'
    };

    return (
        <tr>
            <TableCell style={cellStyle}>{formatDate(searchResult.timestamp)}</TableCell>
            <TableCell style={cellStyle}>{mapInventoryLocation(searchResult.inventoryLocation)}</TableCell>
            <TableCell style={cellStyle}>{searchResult.sourceId}</TableCell>
            <TableCell style={cellStyle}>{searchResult.customsInventoryType}</TableCell>
            <TableCell style={cellStyle}>{searchResult.businessReference}</TableCell>
            <TableCell style={cellStyle}>{searchResult.errorDescription}</TableCell>
            <TableCell style={cellStyle}>
                <IconButton
                    variant="ghost"
                    as={Link}
                    to={"details/" + searchResult.traceId}
                    label=""
                    icon="Show"
                />
            </TableCell>
        </tr>
    );
}

export {RequestSearchResultItem};
