import React from 'react';
import './index.css';
import App from './App';
import {SnackBarContextProvider} from "./providers/snackbar-context";
import * as serviceWorker from './serviceWorker';
import * as ReactDOMClient from 'react-dom/client';
import "@nike/eds/dist/index.css";
import {BrowserRouter} from "react-router-dom";

let requestOverviewContainer = null;
let root = null;

/////for testing purposes
// let containerId = 'root'
// requestOverviewContainer = document.getElementById(containerId);
// root = ReactDOMClient.createRoot(requestOverviewContainer);
// root.render(
//     <React.StrictMode>
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//     </React.StrictMode>
// );
/////


window.renderRequestOverview = (containerId, history) => {
    if (!requestOverviewContainer) {
        requestOverviewContainer = document.getElementById(containerId);
        root = ReactDOMClient.createRoot(requestOverviewContainer);
        root.render(
            <React.StrictMode>
                <BrowserRouter>
                    <SnackBarContextProvider>
                        <App history={history}/>
                    </SnackBarContextProvider>
                </BrowserRouter>
            </React.StrictMode>
        );
        serviceWorker.unregister();
    }
};

window.unmountRequestOverview = containerId => {
    root.unmount()
    requestOverviewContainer = null;
    root = null;
};

if (!document.getElementById('RequestOverview-container')) {
    //root.render(<App />);
    serviceWorker.unregister();
}


