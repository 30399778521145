import { Button, Text } from "@nike/eds";
import { SizedModal } from "../shared/layout/SizedModal";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface DownloadModalInput {
  downloadModalOpen: boolean;
  setDownloadModalOpen: (data: boolean) => void;
  content: string;
  jsonContent: boolean;
}

export const DownloadModal = ({
  downloadModalOpen,
  setDownloadModalOpen,
  content,
  jsonContent,
}: DownloadModalInput): JSX.Element => {
  return !downloadModalOpen ? (
    <div></div>
  ) : (
    <>
      <SizedModal
        isOpen={downloadModalOpen}
        onDismiss={() => setDownloadModalOpen(false)}
        headerSlot={
          <Text font={"title-3"} as={"h3"}>
            Payload
          </Text>
        }
        footerSlot={
          <div
            style={{
              display: "grid",
              gridGap: "10px",
              gridTemplateColumns: "auto auto",
              justifyContent: "start",
            }}
          >
            <CopyToClipboard text={content}>
              <Button size="small" className="eds--dark">
                Copy to clipboard
              </Button>
            </CopyToClipboard>
          </div>
        }
      >
        <Text
          font={"body-3"}
          as={"div"}
          style={{ marginTop: "var(--eds-space-16)" }}
        >
          <pre hidden={!jsonContent}>{content}</pre>

          <div hidden={jsonContent}>{content}</div>
        </Text>
      </SizedModal>
    </>
  );
};
