import React, {useState, useContext, useEffect} from "react";
import {FilterableTable} from "./FilterableTable";
import {useMemo} from "react";
import {Spinner, Button, Icon, Text, Tooltip} from "@nike/eds";
import {RequestDetailsStructuredLog} from "../../graphql/api";
import SnackbarContext from "../../providers/snackbar-context";
import {formatDate} from "../shared/DateFormatter";
import {DownloadModal} from "./DownloadModal";
import * as vkbeautify from "vkbeautify";

function RequestDetailOverviewTab(props) {
    const [loading, setLoading] = useState(true);
    const snackbarCtx = useContext(SnackbarContext);
    const structuredLogsService = props.structuredLogsService;
    const emptyArrayOfEvents: RequestDetailsStructuredLog[] = [];
    const [data, setData] = useState(emptyArrayOfEvents);
    const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
    const [downloadModalJsonContent, setDownloadModalJsonContent] = useState<
        boolean
    >(false);
    const [downloadModalContent, setDownloadModalContent] = useState<string>("");
    const [downloading, setDownloading] = useState(false);
    const [loadingId, setLoadingId] = useState({});

    const downloadPayload = async (e, urlOnly, {row}) => {
        if (row.original.payload === undefined) {
            setDownloading(true);
            setButtonLoading(e);
            const region = row.original.payloadRegion;
            const bucket = row.original.payloadBucket;
            const key = row.original.payloadPath;
            const version = row.original.payloadVersion;
            await structuredLogsService
                .getPayload(region, bucket, key, version, urlOnly)
                .then((result) => {
                    if (urlOnly) {
                        row.original.preSignedUrl = result.data.getPayloadUrlOnly.preSignedUrl;
                    } else {
                        row.original.payload = result.data.getPayload.content;
                        row.original.preSignedUrl = result.data.getPayload.preSignedUrl;
                    }
                })
                .catch((error) => {
                    snackbarCtx.displayMsg(error.message, "error");
                })
                .finally(() => {
                    setDownloading(false);
                    setLoadingId('');
                });
        }
    };

    const setButtonLoading = (e) => {
        const {id} = e.target;
        setLoadingId((ids) => ({
            ...ids,
            [id]: true
        }));
    }

    const handleDownloadClicked = async (e, props) => {
        await downloadPayload(e, true, props);
        const fileName = props.row.original.payloadPath;
        fetch(props.row.original.preSignedUrl).then(function (t) {
            return t.blob().then((b) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", fileName);
                a.click();
            });
        });
    };

    const handleViewClicked = async (e, props) => {
        await downloadPayload(e, false, props);
        try {
            let formattedJson = vkbeautify.json(props.row.original.payload, 2);
            setDownloadModalJsonContent(true);
            setDownloadModalContent(formattedJson);
        } catch {
            setDownloadModalJsonContent(false);
            setDownloadModalContent(props.row.original.payload);
        }
        setDownloadModalOpen(true);
    };

    const columns = useMemo(
        () => [
            {
                Header: "Timestamp",
                accessor: "timestamp",
                Cell: (props) => {
                    return <>{formatDate(props.value)}</>;
                },
            },
            {
                Header: "Duration(ms)",
                accessor: "duration",
            },
            {
                Header: "Service",
                accessor: "componentName",
            },
            {
                Header: "Region",
                accessor: "region",
            },
            {
                Header: "Error description",
                accessor: "errorDescription",
            },
            {
                Header: "Message",
                accessor: "message",
            },
            {
                Header: "Payload",
                accessor: "payloadPath",
                Cell: (props) => {
                    return props.value !== undefined && props.value !== null ? (
                        <div>
                            <Button
                                id={props.row.original.sortKey + 'download'}
                                size="medium"
                                onClick={(e) => {
                                    handleDownloadClicked(e, props);
                                }}
                                disabled={downloading}
                            >
                                {loadingId[props.row.original.sortKey + 'download'] ? (
                                    <Spinner size="medium"/>
                                ) : (
                                    <Icon name="Download" size="s" enableFocus/>
                                )}
                            </Button>
                            {(props.row.original.payloadSize !== undefined && props.row.original.payloadSize > 70000000) ? (
                                <Tooltip bodySlot={"No preview available, use download instead."}>
                                    <Button
                                        id={props.row.original.sortKey + 'view'}
                                        size="medium"
                                        onClick={(e) => {
                                            handleViewClicked(e, props);
                                        }}
                                        disabled
                                    >
                                        {loadingId[props.row.original.sortKey + 'view'] ? (
                                            <Spinner color="white"/>
                                        ) : (
                                            <Icon name="Show" size="s" enableFocus/>

                                        )}
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Button
                                    id={props.row.original.sortKey + 'view'}
                                    size="medium"
                                    onClick={(e) => {
                                        handleViewClicked(e, props);
                                    }}
                                    disabled={downloading}
                                >
                                    {loadingId[props.row.original.sortKey + 'view'] ? (
                                        <Spinner color="white"/>
                                    ) : (
                                        <Icon name="Show" size="s" enableFocus/>

                                    )}
                                </Button>
                            )}
                        </div>
                    ) : (
                        <></>
                    );
                },
            },
        ],
        [loading, downloading]
    );

    useEffect(() => {
        if (loading) {
            structuredLogsService
                .getRequestDetails(props.traceId, props.logType)
                .then((result) => {
                    //array is ordered on timestamp newest to oldest
                    let previousTimestamp = null;
                    for (let item of result.data.getRequestDetails.items
                        .slice()
                        .reverse()) {
                        if (previousTimestamp !== null && previousTimestamp !== undefined) {
                            var utc1 = new Date(previousTimestamp);
                            var utc2 = new Date(item.timestamp);
                            item.duration = Math.floor(utc2.getTime() - utc1.getTime());
                            previousTimestamp = item.timestamp;
                        } else {
                            previousTimestamp = item.timestamp;
                            item.duration = 0;
                        }
                    }

                    setData(result.data.getRequestDetails.items);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [loading]);

    const FetchingData = () => (
        <div style={{margin: "auto", width: "1%"}}>
            <div>
                <Spinner size="large"/>
            </div>
            <div>
                <Text>Loading...</Text>
            </div>
        </div>
    );

    return loading ? (
        <FetchingData/>
    ) : (
        <div>
            <FilterableTable data={data} columns={columns} loading={loading}/>
            <DownloadModal
                downloadModalOpen={downloadModalOpen}
                setDownloadModalOpen={setDownloadModalOpen}
                content={downloadModalContent}
                jsonContent={downloadModalJsonContent}
            />
        </div>
    );
}

export {RequestDetailOverviewTab};
