import React, {useState, useContext, useEffect} from "react";
import {RequestSearchForm} from "./RequestSearchForm";
import {RequestSearchResults} from "./RequestSearchResults";
import {RequestedSearchForm} from "../../model/RequestedSearchForm";
import SnackbarContext from "../../providers/snackbar-context";
import {Box, IconButton} from "@nike/eds";
import useSessionStorage from "../../hooks/useSessionStorage";

export function RequestSearchOverview(props) {

    const structuredLogsService = props.structuredLogsService;
    const snackbarCtx = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [currentSearchForm, setCurrentSearchForm] = useState({});
    let [searchResults, setSearchResults] = useState([]);
    const [ pagingTokensSession, setPagingTokens ] = useSessionStorage("pagingTokens", []);
    let pagingTokens = pagingTokensSession.value;

    const searchHandler = (requestedSearchForm: RequestedSearchForm, restore : boolean) => {
        setCurrentSearchForm(requestedSearchForm)
        // TODO setState is async > how do we fix this so it works like now without accessing the variable directly
        if (restore) {
            pagingTokens = pagingTokens.slice(0, -1);
        } else {
            pagingTokens = []
        }
        searchResults = []
        fetchData(requestedSearchForm, 'down', restore)
    }

    const fetchData = (requestedSearchForm: RequestedSearchForm, direction: string, restore : boolean = false) => {
        setLoading(true);

        if (direction === 'up') {
            // remove next and current tokens from the pagingTokens array to retrieve previous set
            pagingTokens.pop()
            pagingTokens.pop()
        }

        structuredLogsService.listRequests(pagingTokens.at(-1), requestedSearchForm.source,
            requestedSearchForm.sourceId, requestedSearchForm.status, requestedSearchForm.inventoryType,
            requestedSearchForm.inventoryLocation, requestedSearchForm.errorCode, requestedSearchForm.startDate,
            requestedSearchForm.endDate, requestedSearchForm.businessReference)
        .then(result => {
            setLoading(false);
            setSearchResults(result.data.listRequestsDynamicSearch.items)
            if (!restore) {
                setPagingTokens(pagingTokens.concat(result.data.listRequestsDynamicSearch.nextToken))
            }
        })
        .catch(error => {
            setLoading(false);
            snackbarCtx.displayMsg(error.message, "error");
        });

    }

    return (
       <Box className="nesg-grid nesg-grid-md-cols-2 nesg-grid-items-center">
            <RequestSearchForm onSearch={searchHandler}/>
            <span className="eds-spacing--p-8" style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                <IconButton icon="CaretLeft" variant="ghost" size="small" onClick={() => { searchHandler(currentSearchForm, false) }} label="top" />
                <IconButton icon="CaretUp" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'up') }} label="up"/>
                <IconButton disabled={pagingTokens.at(-1) === null} icon="CaretDown" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'down') }} label="down"/>
                <span style={{opacity: '0.25'}}>page {pagingTokens.length}</span>
            </span>
            <RequestSearchResults searchResults={searchResults} loading={loading}/>
            <span className="eds-spacing--p-8" style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                <IconButton icon="CaretLeft" variant="ghost" size="small" onClick={() => { searchHandler(currentSearchForm, false) }} label="top"/>
                <IconButton icon="CaretUp" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'up') }} label="up"/>
                <IconButton disabled={pagingTokens.at(-1) === null} icon="CaretDown" variant="ghost" size="small" onClick={() => { fetchData(currentSearchForm, 'down') }} label="down"/>
                <span style={{opacity: '0.25'}}>page {pagingTokens.length}</span>
            </span>
        </Box>
    )
}

