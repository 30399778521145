import { OktaAuth } from '@okta/okta-auth-js'

const oktaConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER !== undefined ? process.env.REACT_APP_OKTA_ISSUER : '',
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: 'nike.niketech.label-print-manager',
  scopes: ['openid', 'node_management:glds::read:', 'node_management:glds::create:', 'node_management:glds::update:', 'node_management:glds::delete:'],
  pkce: true,
}

export const oktaAuth = new OktaAuth(oktaConfig)
