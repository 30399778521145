import React, { useContext } from "react";
import { Snack, Snackbar } from "@nike/eds";
import SnackbarContext from "../../providers/snackbar-context";

function ApplicationSnackbar(props) {
    const snackbarCtx = useContext(SnackbarContext);

    return (
        <Snackbar>
            <Snack id={"1"} hideDismiss={true} status="error" onDismiss={snackbarCtx.onClose}>
              { snackbarCtx.msg }
            </Snack>
        </Snackbar>
    )
};

export default ApplicationSnackbar;