/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRequest = /* GraphQL */ `
  query GetRequest($traceId: String!) {
    getRequest(traceId: $traceId) {
      messageType
      source
      traceId
      sourceId
      flowId
      customsInventoryType
      duration
      errorCode
      errorDescription
      errorMessage
      payloadRegion
      payloadBucket
      payloadPath
      payloadSize
      payloadVersion
      timestamp
      statusTimestamp
      businessReference
      status
      inventoryLocation
    }
  }
`;
export const getRequestDetails = /* GraphQL */ `
  query GetRequestDetails($traceId: String!, $logType: String!) {
    getRequestDetails(traceId: $traceId, logType: $logType) {
      items {
        hashKey
        sortKey
        timestamp
        componentName
        region
        message
        errorCode
        errorDescription
        errorMessage
        payloadRegion
        payloadBucket
        payloadPath
        payloadSize
        payloadVersion
        properties
      }
    }
  }
`;
export const getPayload = /* GraphQL */ `
  query GetPayload(
    $region: String!
    $bucket: String!
    $key: String!
    $version: String!
  ) {
    getPayload(region: $region, bucket: $bucket, key: $key, version: $version) {
      content
      contentType
      preSignedUrl
    }
  }
`;
export const getPayloadUrlOnly = /* GraphQL */ `
  query GetPayloadUrlOnly(
    $region: String!
    $bucket: String!
    $key: String!
    $version: String!
  ) {
    getPayloadUrlOnly(
      region: $region
      bucket: $bucket
      key: $key
      version: $version
    ) {
      contentType
      preSignedUrl
    }
  }
`;
export const listRequestsDynamicSearch = /* GraphQL */ `
  query ListRequestsDynamicSearch(
    $customsInventoryType: String
    $errorCode: String
    $fromTimestamp: Float
    $inventoryLocation: String
    $messageType: String
    $offsetFromCurrentTimestamp: String
    $offsetToCurrentTimestamp: String
    $sortKey: String
    $source: String
    $sourceId: String
    $status: String
    $toTimestamp: Float
    $businessReference: String
  ) {
    listRequestsDynamicSearch(
      customsInventoryType: $customsInventoryType
      errorCode: $errorCode
      fromTimestamp: $fromTimestamp
      inventoryLocation: $inventoryLocation
      messageType: $messageType
      offsetFromCurrentTimestamp: $offsetFromCurrentTimestamp
      offsetToCurrentTimestamp: $offsetToCurrentTimestamp
      sortKey: $sortKey
      source: $source
      sourceId: $sourceId
      status: $status
      toTimestamp: $toTimestamp
      businessReference: $businessReference
    ) {
      items {
        messageType
        source
        traceId
        sourceId
        flowId
        customsInventoryType
        duration
        errorCode
        errorDescription
        errorMessage
        payloadRegion
        payloadBucket
        payloadPath
        payloadSize
        payloadVersion
        timestamp
        statusTimestamp
        businessReference
        status
        inventoryLocation
      }
      nextToken
    }
  }
`;
